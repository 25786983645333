import { Chain } from 'viem'
import {mainnet} from "wagmi/chains";

export const NETWORKS = {
  mainnet: 1,
  mainnetfork: 31337,
  localhost: 1337,
};

export const mainnetFork = {
  id: 31337,
  name: 'Mainnet Fork',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: [process.env.REACT_APP_MAINNET_FORK_RPC_URL || mainnet.rpcUrls.default.http[0]] },
    default: { http: [process.env.REACT_APP_MAINNET_FORK_RPC_URL || mainnet.rpcUrls.default.http[0]] },
  }
} as const satisfies Chain

export const ROUTES = {
  dashboard: "/",
  vaults: "/vaults",
  notFound: "*",
};

export const STATIC_URLS = {
  tandc: "https://altitude.fi/app-terms-conditions",
  docs: "https://docs.altitude.fi/",
};

export const HEADER_MODAL_STATUS = {
  closed: 0,
  claim: 1,
  connectWallet: 2,
};

export const CONNECT_WALLET = {
  select: 1,
  connected: 2,
};

export const ITEMS_PER_PAGE = 10;

export const DASHBOARD_TAB = {
  deposit: "deposit",
  withdraw: "withdraw",
};

export const BTN_TEXT = {
  proceed: "proceed",
  deposit: "deposit",
  takeLoan: "take loan",
  repay: "repay",
  repayWithdraw: "repay & withdraw",
};

export const STORAGE_KEY = {
  walletType: "walletType",
};

export const TRANSACTION_STATUS = {
  none: 'none',
  initiated: 'initiated',
  processing: 'processing',
  failed: 'failed',
  rejected: 'rejected',
  confirmed: 'confirmed',
};

export type TransactionStatusesKey = keyof typeof TRANSACTION_STATUS
export type TransactionStatus = typeof TRANSACTION_STATUS[TransactionStatusesKey]

export const USER_STATS_HOVER_STATE = {
  closed: 0,
  showApyInfo: 1,
  showBorrowingInfo: 2,
};

// Average time to produce a block per chain
export const BLOCK_TIME = {
  [NETWORKS.mainnet]: 12,
  [NETWORKS.mainnetfork]: 12,
  [NETWORKS.localhost]: 12,
}
