import styled from "styled-components";

import { ThemeAppBackground } from "./theme.styles";

export const AppPage = styled.div`
  background: ${ThemeAppBackground};
  min-height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;
