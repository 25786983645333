import styled from "styled-components";
import { GridVars, ThemeGray900, ThemeSecondaryText } from "../../utils";
import { Text } from "../../utils/styles/text";

export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  background: ${ThemeGray900};
`

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${GridVars.padding.p1} ${GridVars.padding.p3};
  width: 100%;
  max-width: calc(${GridVars.breakpoint.xxl} + ${GridVars.margin.m3} * 2);

  @media (max-width: ${GridVars.breakpoint.sm}) {
    flex-direction: column;
    align-items: center;
    gap: ${GridVars.margin.m3};
  }
`

export const FooterContent = styled.div`
  
`

export const FooterLink = styled.a`
  text-decoration: none;
  color: ${ThemeSecondaryText};
`
export const FooterItem = styled(Text).attrs({ $size: "small" })`
  padding: ${GridVars.padding.p2};
`
