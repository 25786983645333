import styled from "styled-components";
import { GridVars } from "./grid.styles";

interface PageProps {
  readonly $toastBarEnabled?: boolean;
}

export const Page = styled.div<PageProps>`
  display: flex;
  justify-content: center;
  flex: 1;
  margin: ${GridVars.margin.m3} ${GridVars.margin.m3} ${GridVars.margin.m5} ${GridVars.margin.m3};

  @media (max-width: ${GridVars.breakpoint.md}) {
    ${(props) =>
      props.$toastBarEnabled
        ? `margin: calc(${GridVars.margin.m4}) ${GridVars.margin.m3} ${GridVars.margin.m5}
            ${GridVars.margin.m3};`
        : `margin: calc(${GridVars.margin.m3} + 47px) ${GridVars.margin.m3} ${GridVars.margin.m5}
          ${GridVars.margin.m3};
    `}
  }
`;
