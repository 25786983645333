import * as Styles from "./styles";
import { STATIC_URLS } from "../../utils";

const Footer = () => {

  const currentYear = new Date().getFullYear();

  return (
    <Styles.FooterContainer>
      <Styles.FooterWrapper>
        <Styles.FooterContent>
        <Styles.FooterItem>
          <Styles.FooterLink href={STATIC_URLS.tandc} target="_blank" rel="noreferrer noopener">Terms and Conditions</Styles.FooterLink>
        </Styles.FooterItem>
        <Styles.FooterItem>
          <Styles.FooterLink href={STATIC_URLS.docs} target="_blank" rel="noreferrer noopener">Docs</Styles.FooterLink>
        </Styles.FooterItem>
        </Styles.FooterContent>
      <Styles.FooterContent>
        <Styles.FooterItem>
        </Styles.FooterItem>
        <Styles.FooterItem>
          <Styles.FooterLink href="https://altitude.fi" target="_blank" rel="noreferrer noopener">&copy; {currentYear} Altitude</Styles.FooterLink>
        </Styles.FooterItem>
      </Styles.FooterContent>
      </Styles.FooterWrapper>
    </Styles.FooterContainer>
  )
}

export default Footer;
